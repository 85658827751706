import { Routes, Route } from "react-router-dom";
import ErrorPage from "./pages/error-page";
import base64 from "base-64";
import LandingRoute from "./Routes/landing-route/landing.route";

const App = () => {
  // Decode the Base64-encoded URL
  try {
    // Get the encoded URL from the current location
    const encodedUrl = window.location.pathname.substring(1);

    // in case of no encoded url
    if (!encodedUrl) {
      throw new Error("The path does not exist");
    }

    const decodedUrl = base64.decode(encodedUrl);

    if (!decodedUrl) {
      throw new Error("The url is not valid");
    }

    const decodedUrlObject = JSON.parse(decodedUrl);

    return (
      <Routes>
        <Route
          path="/*"
          element={<LandingRoute decodedUrlObject={decodedUrlObject} />}
        ></Route>
      </Routes>
    );
  } catch (error) {
    console.error(error.message);
    return <ErrorPage />;
  }
};

export default App;
