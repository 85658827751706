import { useState } from "react";

import { MdEmail } from "react-icons/md";
import { FaArrowLeft } from "react-icons/fa";

import FormInput from "../form-input/form-input.component";

import verifyEmailOTP from "../../apis/verify-emailOTP";
import generateVerifyEmailBody from "../../utils/verify-email/verify-email-body";

import UserInfo from "../user-info/user-info";

import ProgressIndicator from "../progress-dots/progress-dots.component";

import "./email-verifiction-view.style.scss";
import "../card/card.style.scss";
import BeatSpinner from "../loading-indicator/beat-loader";

import PopupGlobal from "../popup/popup";
import SocialPopupChildComponent from "../popup/child-components/social-media/social-media";

import { checkConditionForSocialPopup } from "../../utils/helpers";

// import SocialMediaLinks from "../links-footer/links-footer";

export const EmailVerificationView = ({
  email,
  eventId,
  eventName,
  uniqueHash,
  promotorId,
  doormanId,
  goBack,
}) => {
  const defaultFormFields = {
    otp: "",
  };
  const [newFormField, setNewFormFields] = useState(defaultFormFields);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [payLoad, setPayLoad] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showGlobalPopUp, setShowGlobalPopUp] = useState(true);

  const { otp } = newFormField;

  const verifyEmailBody = generateVerifyEmailBody(
    eventId,
    email,
    otp,
    uniqueHash
  );

  const verifyHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const data = await verifyEmailOTP(verifyEmailBody);

      if (data.statusCode === 200) {
        setPayLoad(data.payLoad);
        setShowUserInfo(true);
      }
    } catch (err) {
      console.error(err.response ? err.response.data.message : err.message);
      setErrorMessage(err.response ? err.response.data.message : err.message);
      setTimeout(() => setErrorMessage(""), 4000);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setNewFormFields({ ...newFormField, [name]: value });
  };

  const handleKeyPress = (event) => {
    const pattern = /^\d{0,4}$/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar) || event.target.value.length >= 4) {
      event.preventDefault();
    }
  };

  // after pin verification:
  if (showUserInfo && payLoad) {
    return (
      // <div className="user-info-card">
      <div className="profile-card">
        <UserInfo
          eventId={eventId}
          promotorId={promotorId}
          doormanId={doormanId}
          uniqueHash={uniqueHash}
          payLoad={payLoad}
        />

        {showGlobalPopUp &&
          payLoad &&
          checkConditionForSocialPopup(payLoad) && (
            <PopupGlobal
              setShowGlobalPopup={setShowGlobalPopUp}
              showGlobalPopup={showGlobalPopUp}
              ChildComponent={() => (
                <SocialPopupChildComponent
                  instagramLink={payLoad.event.socialMedia.instagramUrl}
                  facebookLink={payLoad.event.socialMedia.facebookUrl}
                  youtubeLink={payLoad.event.socialMedia.youtubeUrl}
                  tiktokLink={payLoad.event.socialMedia.tiktokUrl}
                />
              )}
              popupHeading="Follow us on"
            />
          )}
      </div>
      //   <SocialMediaLinks
      //     facebookLink={payLoad.event.socialMedia.facebookUrl}
      //     instagramLink={payLoad.event.socialMedia.instagramUrl}
      //     youtubeLink={payLoad.event.socialMedia.youtubeUrl}
      //     tiktokLink={payLoad.event.socialMedia.tiktokUrl}
      //   />
      // </div>
    );
  }

  return (
    <div className="profile-card">
      <div className="otp-screen">
        <div className="back-arrow-container">
          <FaArrowLeft onClick={goBack} />
        </div>

        <div className="email-verification-event-name-container">
          <p className="email-verification-event-name">{eventName}</p>
        </div>

        <div className="email-icon-container">
          <MdEmail size={50} />
        </div>

        <div className="verification-messages-container">
          <p className="email-verification-message"> Verify your email </p>
          <p className="email-verification-message-two">
            A 4-digit code has been sent to {email}
          </p>
        </div>

        <form key={errorMessage} onSubmit={verifyHandler}>
          <FormInput
            label="OTP"
            type="number"
            placeholder="OTP"
            name="otp"
            value={otp}
            onChange={onChangeHandler}
            onKeyPress={handleKeyPress}
            required
          />
          <span className="error-message-container">{errorMessage}</span>
          <button className="sign-in-button">
            {isSubmitting ? <BeatSpinner /> : "Verify"}
          </button>
        </form>

        <ProgressIndicator currentStep={2} totalSteps={3} />
      </div>
    </div>
  );
};
