import axios from "axios";
import { baseURL } from "../utils/consts";

const verifyHashApi = async (
  eventId,
  uniqueHash,
  isHashCreated,
  promotorId,
  type,
  inviteType,
  guestName,
  isOneTimeShare,
  ticketType
) => {
  const url = `${baseURL}/invitedVia/expressEntry/verifyHash`;

  const responseObject = {
    eventId: eventId,
    isHashCreated: isHashCreated,
    promotorId: promotorId,
    uniqueHash: uniqueHash ? uniqueHash : undefined,
    isExpressEntry: true,
    type: type,
    inviteType: inviteType,
    guestName: guestName,
    isOneTimeShare: isOneTimeShare ? isOneTimeShare : false,
    ticketType: ticketType,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(
      url,
      responseObject,

      {
        headers: headers,
      }
    );

    const data = await response.data;

    return data;
  } catch (error) {
    throw error;
  }
};

export default verifyHashApi;
