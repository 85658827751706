import React from "react";
import "./progress-dots.style.scss";

const ProgressIndicator = ({ currentStep, totalSteps }) => {
  return (
    <div className="progress-indicator">
      {Array.from({ length: totalSteps }, (_, index) => (
        <span
          key={index}
          className={`dot${currentStep === index + 1 ? " active" : ""}`}
        ></span>
      ))}
    </div>
  );
};

export default ProgressIndicator;
