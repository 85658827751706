export const QRTypes = {
  COMPS: "COMPS",
  comps: "comps",
  reduce: "reduce",
  REDUCE: "REDUCE",
};

export const changeQRColor = (type) => {
  let fgColor;
  switch (type) {
    case QRTypes.COMPS:
    case QRTypes.comps:
      fgColor = "#006400";
      break;
    case QRTypes.REDUCE:
    case QRTypes.reduce:
      fgColor = "#DC3535";
      break;
    default:
      fgColor = "black";
      break;
  }
  return fgColor;
};
