import "./user-info.style.scss";

import { Fragment } from "react";

import QRCode from "qrcode.react";

import { QRTypes, changeQRColor } from "../../utils/QR/get-QRcolor";

import ErrorMessage from "../error-message/error-message";

import ProgressIndicator from "../progress-dots/progress-dots.component";
import { USER_ROLES } from "../../utils/user-roles/user-roles";

import { capitalizeNames } from "../../utils/helpers";

const UserInfo = ({ eventId, promotorId, uniqueHash, payLoad }) => {
  const QRdata = JSON.stringify({
    eventId: eventId,
    promotorId: promotorId,
    code: payLoad.code,
    doormanId: payLoad.doorman,
    uniqueHash: uniqueHash,
    type: payLoad.type,
    isExpressEntry: payLoad.isExpressEntry,
    imageUrl: payLoad.imageUrl,
    fullName: payLoad.fullName,
    invitedBy: payLoad.member.fullName,
    inviteId: payLoad.id,
  });

  const QRcolor = changeQRColor(payLoad.type);

  if (!payLoad.member) {
    return <ErrorMessage />;
  }

  return (
    <Fragment>
      <div className="event-information">
        <p>testing out the CI/CD pipelines</p>
        <p className="event-name">{payLoad.event.eventName}</p>
        <div className="profile-image">
          <img src={payLoad.event.imageUrl} alt="Profile" />
        </div>

        <p className="user-name">{capitalizeNames(payLoad.fullName)}</p>
      </div>

      <div className="profile-info">
        <h2 className="invited-by">Invited by</h2>
        <p className="invited-by-name">
          {payLoad.member.userType === USER_ROLES.ADMIN
            ? "Event Owner"
            : capitalizeNames(payLoad.member.fullName)}
        </p>
      </div>

      {payLoad.email.includes("noreply") &&
      payLoad.member.userType === USER_ROLES.ADMIN &&
      (payLoad.type === QRTypes.comps || payLoad.type === QRTypes.COMPS) ? (
        <p className="QR-optional-text">Complementary QR Code</p>
      ) : (
        ""
      )}

      <div className="QR-content">
        {payLoad.type === QRTypes.REDUCE || payLoad.type === QRTypes.reduce ? (
          <div className="QR-reduce-type-text-container">
            <p className="QR-reduce-type-text"> Reduce Ticket</p>
          </div>
        ) : (
          ""
        )}

        {payLoad.type === QRTypes.COMPS || payLoad.type === QRTypes.comps ? (
          <div className="QR-reduce-type-text-container">
            <p className="QR-comps-type-text"> Comp Ticket</p>
          </div>
        ) : (
          ""
        )}

        <p className="message-text">
          {payLoad.isVerified
            ? "Your check-in was successfully verified"
            : "Present this barcode at the door for expedited entry"}
        </p>

        <div className="QR-code-container">
          <QRCode value={QRdata} fgColor={QRcolor} />

          <p className="code"> code: {payLoad.code}</p>
        </div>
      </div>

      <div className="text-container">
        <p className="venue-text">
          <strong>Venue:</strong> {payLoad.event.location}
        </p>
      </div>

      <ProgressIndicator currentStep={3} totalSteps={3} />
    </Fragment>
  );
};

export default UserInfo;
