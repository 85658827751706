import React from "react";
import "./error-page.style.scss";

const ErrorPage = () => {
  return (
    <div className="error-page-container">
      <h1>404 Error</h1>
      <p>Oops! The page you are looking for does not exist.</p>
    </div>
  );
};

export default ErrorPage;
