import axios from "axios";
import { baseURL } from "../utils/consts";

const verifyEmailOTP = async (responseUser) => {
  const responseObject = responseUser;

  try {
    const response = await axios.post(
      `${baseURL}/invitedVia/expressEntry/verifyEmail`,

      responseObject,

      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.data;
    return data;
  } catch (err) {
    throw err;
  }
};

export default verifyEmailOTP;
