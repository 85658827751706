import { Fragment } from "react";
import { ScaleLoader } from "react-spinners";

const LoadingSpinner = ({ color = "#4b0082" }) => {
  return (
    <Fragment>
      <ScaleLoader color={color} />
    </Fragment>
  );
};

export default LoadingSpinner;
