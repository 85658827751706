import React, { Fragment } from "react";

import "./popup.scss";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const PopupGlobal = ({
  popupHeading,
  ChildComponent,
  showGlobalPopup,
  setShowGlobalPopup,
  popupId,
}) => {
  return (
    <Popup
      open={showGlobalPopup}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      overlayStyle={{
        transitionDelay: "1000",
        background: "rgba(0, 0, 0, 0.5)",
      }}
      contentStyle={{
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
        background: "rgb(255, 255, 255)",
        width: "300px",
        height: "150px",
        padding: "20px",
      }}
      className="popup-bounce"
    >
      <Fragment>
        <div className="gbl-popup-container">
          <button onClick={() => setShowGlobalPopup(false)} className="close">
            &times;
          </button>
          <h1 className="gbl-popup-heading">{popupHeading}</h1>
          <ChildComponent />
        </div>
      </Fragment>
    </Popup>
  );
};

export default PopupGlobal;
