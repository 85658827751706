const generateVerifyEmailBody = (eventId, email, otp, uniqueHash) => {
  return {
    eventId: eventId,
    email: email,
    emailOTP: otp,
    uniqueHash: uniqueHash,
  };
};

export default generateVerifyEmailBody;
