export const capitalizeNames = (fullName) => {
  const names = fullName.split(" ");
  const capitalizedNames = names.map(
    (name) => name.charAt(0).toUpperCase() + name.slice(1)
  );
  return capitalizedNames.join(" ");
};

export const checkConditionForSocialPopup = (payLoad) => {
  if (!payLoad.event.socialMedia) return false;

  const { facebookUrl, instagramUrl, tiktokUrl, youtubeUrl } =
    payLoad.event.socialMedia;

  // Check if each URL is truthy (not null, undefined, or empty string)
  return [facebookUrl, instagramUrl, tiktokUrl, youtubeUrl].some((url) =>
    Boolean(url)
  );
};
