import React from "react";
import { FaInstagram, FaFacebook, FaYoutube, FaTiktok } from "react-icons/fa";

import "../../../links-footer/links-footer.scss";
import "./social-media.scss";

const SocialPopupChildComponent = ({
  instagramLink,
  facebookLink,
  youtubeLink,
  tiktokLink,
}) => {
  return (
    <div className="gbl-social-child">
      {instagramLink && (
        <a
          href={instagramLink}
          target="_blank"
          rel="noopener noreferrer"
          className="social-media-link icon instagram"
        >
          <FaInstagram size={29} />
        </a>
      )}
      {facebookLink && (
        <a
          href={facebookLink}
          target="_blank"
          rel="noopener noreferrer"
          className="social-media-link icon facebook"
        >
          <FaFacebook size={29} />
        </a>
      )}
      {youtubeLink && (
        <a
          href={youtubeLink}
          target="_blank"
          rel="noopener noreferrer"
          className="social-media-link icon youtube"
        >
          <FaYoutube size={29} />
        </a>
      )}
      {tiktokLink && (
        <a
          href={tiktokLink}
          target="_blank"
          rel="noopener noreferrer"
          className="social-media-link icon tiktok"
        >
          <FaTiktok size={29} />
        </a>
      )}
    </div>
  );
};

export default SocialPopupChildComponent;
