const generateBodyObject = (
  eventId,
  promotorId,
  uniqueHash,
  fullName,
  email
) => {
  return {
    eventId: eventId,
    promotorId: promotorId,
    uniqueHash: uniqueHash,
    fullName: fullName,
    email: email,
    hasProfileImage: true,
  };
};

export default generateBodyObject;
