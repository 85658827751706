import UserCredentials from "../../components/user-credentials/user-credentials.component";
import "./landing-route.style.scss";

const LandingRoute = ({ decodedUrlObject }) => {
  return (
    <div className="landing-route">
      <UserCredentials decodedUrlObject={decodedUrlObject} />
    </div>
  );
};

export default LandingRoute;
