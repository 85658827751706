import { useEffect, useState } from "react";
import "../card/card.style.scss";

// import { useDispatch , useSelector} from "react-redux";
// import { setFormFields } from "../../store/form-fields/form-fields-action";
// import { selectFormFields } from "../../store/form-fields/form-fields-selector";

import { AiOutlineCamera } from "react-icons/ai";
import { ReactComponent as TallyLogo } from "../../assets/Frame 2.svg";

import verifyHashApi from "../../apis/verify-hash";

import FormInput from "../form-input/form-input.component";
import validateEmail from "../../utils/email-validation/email-validation";

import generateEmailOTP from "../../apis/generate-EmailOTP";
import generateBodyObject from "../../utils/generate-email-otp/email-otp-body";
import updateGuestNameAPI from "../../apis/update-guest";

import UserInfo from "../user-info/user-info";

import ProgressIndicator from "../progress-dots/progress-dots.component";

import { EmailVerificationView } from "../email-verification-view/email-verification-view.component";

import LoadingSpinner from "../loading-indicator/loading-indicator.component";
import BeatSpinner from "../loading-indicator/beat-loader";

import { PROFILE_URL } from "../../utils/profile-image/upload-profile";
import profileImageUploadReq from "../../utils/profile-image/upload-profile";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import "./user-credentials.style.scss";
// import SocialMediaLinks from "../links-footer/links-footer";
import PopupGlobal from "../popup/popup";
import SocialPopupChildComponent from "../popup/child-components/social-media/social-media";

import { checkConditionForSocialPopup } from "../../utils/helpers";

const UserCredentials = ({ decodedUrlObject }) => {
  const eventId = decodedUrlObject.eventId;
  const promotorId = decodedUrlObject.promotorId;
  const doormanId = decodedUrlObject.doormanId;
  const uniqueHash = decodedUrlObject.uniqueHash;
  const eventName = decodedUrlObject.eventName;
  const isHashCreated = decodedUrlObject.isHashCreated;
  const type = decodedUrlObject.type;
  const inviteType = decodedUrlObject.inviteType;
  const guestName = decodedUrlObject.guestName;
  const isOneTimeShare = decodedUrlObject.isOneTimeShare;
  const ticketType = decodedUrlObject.ticketType;

  // empty form fields
  const defaultFormFields = {
    fullName: "",
    email: "",
    fullNamePopUp: "",
  };

  //redux state management:
  // const formFields = useSelector(selectFormFields);
  // const dispatch = useDispatch();

  const [newFormFields, setNewFormFields] = useState(defaultFormFields);
  const [errorMessage, setErrorMessage] = useState("");
  const [showOTPView, setShowOTPField] = useState(false);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [payLoad, setPayLoad] = useState({});
  const [isLoading, setIsLoading] = useState(true); // state for card loading
  const [isSubmitting, setIsSubmitting] = useState(false); // state for submit button loading
  const [image, setImage] = useState(PROFILE_URL);
  const [imageInput, setImageInput] = useState(null);
  const [showPopup, setShowPopUp] = useState(false);
  const [showGlobalPopUp, setShowGlobalPopUp] = useState(true);
  const storedUniqueHash = sessionStorage.getItem("storedUniqueHash");

  const { fullName, email, fullNamePopUp } = newFormFields;

  useEffect(() => {
    const verifyHash = async () => {
      try {
        const data = await verifyHashApi(
          eventId,
          uniqueHash ? uniqueHash : storedUniqueHash,
          isHashCreated,
          promotorId,
          type,
          inviteType,
          guestName,
          isOneTimeShare,
          ticketType
        );

        setPayLoad(data.payLoad);

        if (!storedUniqueHash) {
          sessionStorage.setItem("storedUniqueHash", data.payLoad.uniqueHash);
        }

        conditionChecksForVerifyHashAPI(data);

        conditionsCheckForQuickInvite(data);
      } catch (err) {
        console.error(err.response?.data?.message);
      } finally {
        setIsLoading(false);
      }
    };
    verifyHash();
  }, []);

  const conditionsCheckForQuickInvite = (data) => {
    // console.log(payLoad, "beginning of quick invte check");
    if (
      data.payLoad.isQuickInvite &&
      data.payLoad.isGuestInfoUpdated === false
    ) {
      setShowPopUp(true);
    }
  };

  const conditionChecksForVerifyHashAPI = (data) => {
    if (data.payLoad.isEmailVerified) {
      // setPayLoad(data.payLoad);
      // console.log(
      //   payLoad,
      //   "initial payload when verify hash condition becomes true to go to User info screen"
      // );

      setShowUserInfo(true);
    } else if (data.payLoad.email) {
      // setPayLoad(data.payLoad);
      setShowOTPField(true);
      // console.log(payLoad, "show otp screen");
    }
  };

  // submit Full Name and Email handler
  const submitHandler = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    // emailOtp API's Body generator function
    const generateEmailOtpBody = generateBodyObject(
      eventId,
      promotorId,
      uniqueHash ? uniqueHash : storedUniqueHash,
      fullName,
      email
    );

    try {
      if (!validateEmail(email)) {
        throw new Error("Invalid email");
      }

      if (image === PROFILE_URL) {
        throw new Error("Please Upload your image and try again");
      }

      const data = await generateEmailOTP(generateEmailOtpBody);
      setPayLoad(data.payLoad);

      const signedUrl = data.payLoad.signedUrl;

      if (data.payLoad.isEmailVerified) {
        setShowUserInfo(true);
        // setPayLoad(data.payLoad);
        // console.log(payLoad, "user info screen from first screen");
      } else {
        const imageUploadResponse = await profileImageUploadReq(
          image,
          signedUrl
        );

        if (imageUploadResponse.ok) {
          setShowOTPField(true);
          // console.log(payLoad, "otp screen from first screen");
          // setPayLoad(data.payLoad);
        }
      }
    } catch (err) {
      console.error(err);
      console.error(err.response ? err.response.data.message : err.message);
      setErrorMessage(err.response ? err.response.data.message : err.message);
      setTimeout(() => setErrorMessage(""), 4000);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Form fields changing function:
  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    const updatedFormFields = { ...newFormFields, [name]: value };
    setNewFormFields(updatedFormFields);
    // dispatch(setFormFields(updatedFormFields));
  };

  // image change function
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const CustomPopUp = () => {
    const handleSubmit = async (e) => {
      e.preventDefault();
      // console.log(payLoad, "on beiginng of submitting of popup");

      if (!newFormFields.fullNamePopUp.trim()) {
        alert("Full Name cannot be empty!");
        return;
      } else {
        try {
          setShowPopUp(false);

          const quickInviteResponse = await updateGuestNameAPI(
            eventId,
            payLoad.id,
            uniqueHash,
            fullNamePopUp
          );

          setPayLoad(quickInviteResponse.payLoad);

          // console.log(payLoad, "on end of calling of quickInviteAPI");
        } catch (e) {
          console.error(e);
        }
      }
    };

    return (
      <Popup
        open={showPopup}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        overlayStyle={{
          transitionDelay: "1000",
          background: "rgba(0, 0, 0, 0.5)",
        }}
        contentStyle={{
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          margin: "auto",
          background: "rgb(255, 255, 255)",
          width: "300px",
          height: "200px",
          padding: "20px",
        }}
        arrowStyle={{
          color: "rgb(255, 255, 255)",
        }}
      >
        <form onSubmit={handleSubmit}>
          <h2 className="custom-popup-heading"> Please enter you name </h2>
          <FormInput
            label="Full Name"
            type="text"
            placeholder="Full Name"
            name="fullNamePopUp"
            value={fullNamePopUp}
            onChange={onChangeHandler}
            required
          />
          <button className="sign-in-button" onClick={handleSubmit}>
            Submit
          </button>
        </form>
      </Popup>
    );
  };

  if (isLoading) {
    return (
      <div className="profile-card">
        <LoadingSpinner />
      </div>
    );
  }

  if (showOTPView) {
    return (
      <EmailVerificationView
        email={email}
        eventId={eventId}
        eventName={eventName}
        uniqueHash={uniqueHash ? uniqueHash : storedUniqueHash}
        promotorId={promotorId}
        doormanId={doormanId}
        goBack={() => {
          setShowOTPField(false);
        }}
      />
    );
  }

  if (showUserInfo && payLoad) {
    return (
      // <div className="user-info-card">
      <div className="profile-card">
        <UserInfo
          eventId={eventId}
          promotorId={promotorId}
          uniqueHash={uniqueHash ? uniqueHash : storedUniqueHash}
          payLoad={payLoad}
        />
        {showPopup && <CustomPopUp />}
        {showGlobalPopUp &&
          !showPopup &&
          checkConditionForSocialPopup(payLoad) && (
            <PopupGlobal
              setShowGlobalPopup={setShowGlobalPopUp}
              showGlobalPopup={showGlobalPopUp}
              ChildComponent={() => (
                <SocialPopupChildComponent
                  instagramLink={payLoad.event.socialMedia.instagramUrl}
                  facebookLink={payLoad.event.socialMedia.facebookUrl}
                  youtubeLink={payLoad.event.socialMedia.youtubeUrl}
                  tiktokLink={payLoad.event.socialMedia.tiktokUrl}
                />
              )}
              popupHeading="Follow us on"
            />
          )}
      </div>
      // </div>
    );
  }

  return (
    <div className="profile-card">
      <TallyLogo className="event-app-logo" />
      <p className="form-heading"> {eventName}</p>

      <form key={errorMessage} onSubmit={submitHandler}>
        <div className="profile-image-container">
          <img src={image} alt="Profile" />
          <input
            type="file"
            id="image-input"
            accept="image/*"
            onChange={handleImageChange}
            ref={(input) => setImageInput(input)}
            style={{ display: "none" }}
          />

          <button
            className="change-image-btn"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              imageInput.click();
            }}
          >
            <AiOutlineCamera size={20} />
          </button>
        </div>

        <FormInput
          label="Full Name"
          type="text"
          placeholder="Full Name"
          name="fullName"
          value={fullName}
          onChange={onChangeHandler}
          required
        />
        <FormInput
          label="Email"
          type="email"
          placeholder="Email"
          name="email"
          value={email}
          onChange={onChangeHandler}
          required
        />

        <span className="error-message-container">{errorMessage}</span>
        <button className="sign-in-button">
          {isSubmitting ? <BeatSpinner color="white" /> : "Submit"}
        </button>

        <label className="form-checkbox-label">
          <input
            type="checkbox"
            required
            className="form-checkbox"
            defaultChecked
          />{" "}
          I agree to{" "}
          <a
            href="https://mytallyapp.com/Terms-and-Conditions.pdf"
            className="form-checkbox-statement"
          >
            terms and conditions
          </a>
        </label>
      </form>
      <ProgressIndicator currentStep={1} totalSteps={3} />
    </div>
  );
};

export default UserCredentials;

// <SocialMediaLinks
// instagramLink={payLoad.event.socialMedia.instagramUrl}
// facebookLink={payLoad.event.socialMedia.facebookUrl}
// youtubeLink={payLoad.event.socialMedia.youtubeUrl}
// tiktokLink={payLoad.event.socialMedia.tiktokUrl}
// />
